import React from 'react';
import { useHistory } from 'react-router-dom';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import { settlementsRoute } from 'app/containers/routes';
import { sandboxUrl } from 'common/constants';
import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const GetStartedCompleted = ({ isSandboxMode }) => {
  const history = useHistory();

  const handleVerifyClick = () => {
    if (isSandboxMode) {
      return;
    }

    history.push(settlementsRoute, { showKyb: true });
  };

  return (
    <div className={styles.root} data-test="get-started-completed">
      <div className={styles.logo}>
        <LogoxMoney />
      </div>
      <Heading level="1" size="1" color="blue">
        What&apos;s next?
      </Heading>
      <ol>
        <li className={styles.section}>
          <Heading size="2" level="2">
            1st. Verify your business information
          </Heading>
          <ol className={styles.instructions}>
            <li className={styles.item}>
              <Text>
                Submit your business information and allow our team two business
                days to review your data.
              </Text>
              <div className={styles.buttonContainer}>
                <Button
                  name="integrations-link"
                  kind="subtle"
                  icon={
                    <Icon.ShieldCheck
                      fill={colors['rebranding-md-color-brand-purple']}
                      width={28}
                      height={28}
                    />
                  }
                  onClick={handleVerifyClick}
                >
                  <button type="button" data-test="verify-business-button">
                    Verify business
                  </button>
                </Button>
              </div>
            </li>
          </ol>
        </li>
        <li className={styles.section}>
          <Heading size="2" level="2">
            2nd. Test xMoney payments
          </Heading>
          <ol className={styles.instructions}>
            <li className={styles.item}>
              <Text>
                Use Sandbox to test crypto payments made to your business via
                online store or email
              </Text>
              <div className={styles.buttonContainer}>
                <Button
                  name="settlements-link"
                  kind="subtle"
                  icon={
                    <Icon.CodeRec
                      fill={colors['rebranding-md-color-brand-purple']}
                      width={28}
                      height={28}
                    />
                  }
                >
                  <a href={sandboxUrl}>Explore in test mode</a>
                </Button>
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default GetStartedCompleted;
