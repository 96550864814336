import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { motion, useAnimation } from 'framer-motion';
import { Redirect } from 'react-router-dom';
import {
  integrationStatuses,
  complianceStatuses,
  signedUp,
  emailConfirmedIntegration,
  kybStarted,
  kybBaseInfoSubmitted,
} from 'common/constants';
import { ordersRoute } from 'app/containers/routes';
import {
  selectors as sessionSelectors,
  actions as sessionActions,
} from 'session';
import Layout from 'common/containers/Layout';
import { selectors as featuresSelectors } from 'features';
import CompanyInfoContainer from '../containers/CompanyInfo';
import OnboardingLayout from '../components/OnboardingLayout';
import RegistrationCompleteContainer from '../containers/RegistrationComplete';
import GetStartedCompleted from '../components/GetStartedCompleted';

const GetStarted = ({
  integrationStatus,
  complianceStatus,
  setComplianceStatus,
  isSandboxMode,
}) => {
  const animationControls = useAnimation();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    animationControls.set({ opacity: 0 });
    animationControls.start({
      opacity: 1,
    });
  }, [integrationStatus, complianceStatus]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const updateComplianceStatus = (newStatus) => {
    setComplianceStatus(newStatus);
  };

  if (integrationStatus === signedUp) {
    return <RegistrationCompleteContainer />;
  }

  if (
    integrationStatus === emailConfirmedIntegration &&
    complianceStatus === kybStarted
  )
    return (
      <Layout>
        <OnboardingLayout
          integrationStatus={integrationStatus}
          complianceStatus={complianceStatus}
        >
          <motion.div animate={animationControls}>
            <CompanyInfoContainer
              updateComplianceStatus={updateComplianceStatus}
            />
          </motion.div>
        </OnboardingLayout>
      </Layout>
    );

  if (complianceStatus === kybBaseInfoSubmitted) {
    return (
      <Layout>
        <motion.div animate={animationControls}>
          <GetStartedCompleted isSandboxMode={isSandboxMode} />
        </motion.div>
      </Layout>
    );
  }

  return <Redirect to={{ pathname: ordersRoute }} />;
};

GetStarted.propTypes = {
  integrationStatus: PropTypes.oneOf(integrationStatuses),
  complianceStatus: PropTypes.oneOf(complianceStatuses),
  setComplianceStatus: PropTypes.func.isRequired,
  isInvoicingEnabled: PropTypes.bool,
};

const ConnectedGetStarted = connect(
  (state) => ({
    integrationStatus:
      sessionSelectors.activeOrganization(state)?.integrationStatus,
    complianceStatus:
      sessionSelectors.activeOrganization(state)?.complianceStatus,
    isSandboxMode: !!featuresSelectors.getOnboardingFlags(state).sandboxMode,
  }),
  {
    setComplianceStatus: sessionActions.organizationComplianceStatus,
  }
)(GetStarted);

export default memo(ConnectedGetStarted);
